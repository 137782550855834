import { StaticImage } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import { handleSubmitForm } from "../utils/form"

import "../styles/contact.scss"

export default function Contact({ pageContext }) {
  const email = useMemo(() => "harmo01@outlook.com", [])

  return (
    <Layout pageContext={pageContext}>
      <div className="contact-page">
        <div className="page-banner banner" />
        <div className="contact-container">
          <form
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            method="POST"
            netlify
            id="contact-page"
            name="contact-page"
            onSubmit={e =>
              handleSubmitForm(e, () =>
                alert(pageContext.messages["form_success"])
              )
            }
          >
            <input type="hidden" name="form-name" value="contact-page" />
            <h3>
              <FormattedMessage id="contact_form-title" />
            </h3>
            <h4>
              <FormattedMessage id="contact_form-subtitle" />
            </h4>
            <input
              placeholder={pageContext.messages["global_name"]}
              name="name"
              required
              id="name"
            />
            <input
              placeholder={pageContext.messages["global_email"]}
              name="email"
              id="email"
              required
              pattern="(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}"
              title="email not valid"
            />
            <input
              placeholder={pageContext.messages["global_phone"]}
              name="phone"
            />
            <button type="submit">
              <FormattedMessage id="contact_form-send" />
            </button>
          </form>
          <div className="divider" />
          <div className="communicate">
            <h3>
              <FormattedMessage id="global_contact" />
            </h3>
            <h4>
              <FormattedMessage id="global_moshe" />
            </h4>
            <h4>
              <a href={"tel:*+972526626608"}>052-662-6608</a>
            </h4>
            <h4>
              <a href={`mailto:${email}`}>{email}</a>
            </h4>
            <div className="social">
              <a href="https://wa.me/+972526626608">
                <StaticImage
                  className="social-icon"
                  src="../assets/whatsapp-contact.png"
                  alt="whatsapp"
                />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100070644167351">
                <StaticImage
                  className="social-icon facebook"
                  src="../assets/facebook-contact.png"
                  alt="facebook"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export { Head } from "../components/Head"
